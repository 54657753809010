<template>
  <Validator
    ref="validator"
    class="checkbox__validator"
    show-error-on-value-change
    :value="modelValue"
    :rules="rules"
    :style="{ 'padding-bottom': rules.length > 0 ? undefined : '10px' }"
    :message-id="messageId"
    align-left
  >
    <div
      :id="checkboxId ? checkboxId : undefined"
      ref="checkboxWrapper"
      class="checkbox__wrapper"
      :class="{ invalid: valid === false }"
      :data-cy="dataCy"
      :aria-checked="checkboxState()"
      :tabindex="disabled ? undefined : 0"
      role="checkbox"
      :aria-labelledby="checkboxLabelId"
      :aria-describedby="!valid ? messageId : undefined"
      :aria-controls="
        checkboxChildElements.length === 0
          ? undefined
          : checkBoxChildElementMap()
      "
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @blur="blur()"
      @click.prevent="change"
      @keydown.space.enter.prevent.self="change"
    >
      <div class="checkbox__container">
        <Icon
          class="cb-icon"
          :name="checkboxType"
          :class="{ 'align-start': alignBoxStart }"
          height="21"
        />
        <div
          :id="checkboxLabelId"
          class="label"
          data-cy="checkboxLabel"
          :class="{ disabled: disabled }"
        >
          <div v-if="htmlLabel" v-html="htmlLabel" />
          <slot v-else name="label" />
        </div>
      </div>
    </div>
  </Validator>
</template>

<script>
import { uniqueId } from 'src/lib/helpers'
import Icon from '../icon/icon.vue'
import Validator from './validator.vue'
import VueSecureHTML from 'vue-html-secure'

export default {
  name: 'CheckboxInput',
  components: {
    Validator,
    Icon
  },
  props: {
    dataCy: { type: String, default: undefined },
    modelValue: { type: Boolean, default: undefined },
    label: { type: String, default: undefined },
    checkboxId: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    alignBoxStart: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    checkboxChildElements: { type: Array, default: () => [] },
    isMixedState: {
      type: Boolean,
      default: false
    },
    clickhandler: { type: Function, default: undefined }
  },
  emits: ['dirty', 'update:modelValue'],
  data() {
    return {
      checkboxStyle: undefined,
      initValue: this.modelValue,
      hover: false,
      isDirty: false,
      messageId: `mess-${uniqueId()}`,
      valid: undefined
    }
  },
  computed: {
    checkboxLabelId() {
      return `checkbox-label-${uniqueId()}`
    },
    attrs() {
      return {
        ...this.$attrs,
        label: undefined
      }
    },
    htmlLabel() {
      if (this.label) {
        return VueSecureHTML.safeHTML(this.label)
      } else {
        return false
      }
    },
    checkboxType() {
      if (this.modelValue) {
        return this.disabled ? 'checkbox--filled-disabled' : 'checkbox--filled'
      } else {
        return this.disabled
          ? 'checkbox--empty-disabled'
          : this.hover
          ? 'checkbox--hovered'
          : 'checkbox--empty'
      }
    }
  },
  methods: {
    change() {
      if (this.disabled) {
        return
      }

      this.valid = undefined

      if (this.clickhandler) {
        this.clickhandler()
      }

      this.$emit('update:modelValue', !this.modelValue)

      if (!this.isDirty) {
        this.$emit('dirty')
        this.isDirty = true
      }
    },
    focus() {
      this.$refs.checkboxWrapper.focus()
    },
    blur() {
      this.validate(true)
    },
    validate(showError, focus = false) {
      this.valid = this.$refs.validator?.validate(showError)

      if (!this.valid && focus) {
        this.focus()
      }

      return this.valid
    },
    reset() {
      this.$emit('update:modelValue', this.initValue)
      this.$refs.validator.reset()
    },
    checkBoxChildElementMap() {
      const length = this.checkboxChildElements.length
      let elements = ''

      this.checkboxChildElements.map((cat, index) => {
        elements += cat.toLowerCase()
        elements += length === index + 1 ? '' : ' '
      })

      return elements
    },
    isGroupPermissionStateMixed(groupId) {
      return this.$store.getters[
        'contactPermissions/isGroupPermissionStateMixed'
      ](groupId)
    },
    checkboxState() {
      let mixed

      if (this.checkboxChildElements.length > 0) {
        mixed = this.isGroupPermissionStateMixed(this.checkboxId.toUpperCase())
      }

      return mixed ? 'mixed' : this.modelValue ? 'true' : 'false'
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox__wrapper {
  margin: 0 -20px;
  padding: 20px;

  &.invalid:focus {
    border: 1px solid var(--error-color);
    animation: wiggle 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
    padding: 20px;
  }
}

.checkbox__container {
  display: flex;
}

.label {
  margin-left: 12px;
  color: var(--root-color);
  cursor: pointer;

  &.disabled {
    color: #888;
  }
}

.cb-icon {
  margin-top: -3px;
  align-self: center;
  cursor: pointer;

  &.align-start {
    align-self: flex-start;
  }
}
</style>
